import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { Box } from '@mui/material';

import { SiteMeta } from '../atoms/site-meta';
import TitledCard from '../atoms/titled-card';
import LinkCard from '../molecules/link-card';
import Adsense from '../molecules/adsense';
import Layout from '../organisms/layout';
import TagCloud from '../organisms/tag-cloud';

import { IndexTemplateQuery } from '../../../types/graphql-types';
import PathUtils from '../../utils/path-utils';

const IndexTemplate = () => (
  <StaticQuery
    query={graphql`
      query IndexTemplate {
        allMarkdownRemark {
          edges {
            node {
              fields {
                slug
              }
              excerpt(truncate: true)
              frontmatter {
                title
                type
                image {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    fluid {
                      aspectRatio
                      base64
                      sizes
                      src
                      srcSet
                    }
                  }
                }
                thumb {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    fluid {
                      aspectRatio
                      base64
                      sizes
                      src
                      srcSet
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data: IndexTemplateQuery) => {
      let edges = data.allMarkdownRemark.edges.filter(x => x.node?.frontmatter?.type !== 'fixed');
      edges.sort(
        (x, y) =>
          (PathUtils.slugToDateName(y.node?.fields?.slug ?? '').date?.getTime() ?? 0) -
          (PathUtils.slugToDateName(x.node?.fields?.slug ?? '').date?.getTime() ?? 0)
      );

      return (
        <Layout
          asides={[
            <TitledCard title="タグクラウド" key="tag-cloud">
              <TagCloud />
            </TitledCard>,
          ]}
          asidesSticky={[<Adsense />]}
        >
          <SiteMeta />

          <Box sx={{ listStyleType: 'none', m: 0, display: 'grid', gap: 2 }}>
            {edges.map(edge => {
              const post = edge.node!;
              const title = post.frontmatter?.title ?? post.fields?.slug ?? '';
              const dateName = PathUtils.slugToDateName(post.fields?.slug ?? '');
              return (
                <LinkCard
                  key={dateName.name}
                  title={title}
                  to={'/' + dateName.name}
                  image={
                    post.frontmatter?.thumb ? (
                      <Img style={{ width: '100%', height: '100%' }} fluid={post.frontmatter?.thumb?.childImageSharp?.fluid as any} alt={title} />
                    ) : (
                      post.frontmatter?.image && (
                        <Img style={{ width: '100%', height: '100%' }} fluid={post.frontmatter?.image?.childImageSharp?.fluid as any} alt={title} />
                      )
                    )
                  }
                  date={dateName.date}
                >
                  <p dangerouslySetInnerHTML={{ __html: post.excerpt ?? '' }} itemProp="description" />
                </LinkCard>
              );
            })}
          </Box>
        </Layout>
      );
    }}
  />
);

export default IndexTemplate;
