import React, { useEffect } from 'react';

const Adsense = () => {
  // const [isabs, setIsabs] = useState<number>(0);

  useEffect(() => {
    if (window) {
      (window as any).adsbygoogle = (window as any).adsbygoogle ?? [];
      (window as any).adsbygoogle.push({});
      // setIsabs(1);
    } else {
      // setIsabs(2);
    }
  }, []);

  return (
    <>
      {/* <div> {process.env.NODE_ENV}</div>
      <div>{isabs}</div> */}
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-8314262107548467"
        data-ad-slot="4783416292"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </>
  );
};
export default Adsense;
